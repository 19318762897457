import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { Button, Modal, Card, Col, Form, Row } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search, CSVExport } from 'react-bootstrap-table2-toolkit';
import paginationFactory, { PaginationProvider, PaginationListStandalone, SizePerPageDropdownStandalone } from 'react-bootstrap-table2-paginator';
import { Fetcher } from "../../Helpers/fetcher.js";
import { Notify } from "../../Helpers/notification.js";
import 'bootstrap/dist/css/bootstrap.css';
import Dropdown from 'react-bootstrap/Dropdown';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import { cloneDeep, isEmpty } from 'lodash';
import Cookies from 'js-cookie';

import "./index.css";
class UserManagement extends Component {
	constructor(props) {

		super(props);
		this.state = {
			userList: [],
			adminCredits: 0,
			creditsToAdd: 0,
			creditsToRemove: 0,
			confirmDelete: false,
			confirmUpdateAccountType: false,
			selectedUserId: 0,
			showAddCreditModal: false,
			showRemoveCreditModal: false,
			password: '',
			confirmPassword: '',
			showPwdModal: false,
			confirmRemoveSettingStatus: false,
			selectedSubId: 0,
			selectedSettingUserId  : 0,
			selectedAllocateApiCliUserId : '',
			selectedAllocateDedicatedCliUserId : '',
			confirmAllocateApiCliUserId : false,
			confirmAllocateDedicatedCliUserId : false,
			manualAllocation : false,
			manualDedicatedAllocation : false,
			bulkAllocation : false,
			bulkDedicatedAllocation: false,
			cliList : [],
			dedicatedCliList: [],
			allocateApiCliId : '',
			allocateDedicatedCliId: '',
			cliRange : '',
			dedicatedCliRange: '',
			cliId : '',
			dedicatedCliId: '',
			allocatedCliList: [],
			allocatedDedicatedCliList: [],
			allocatedCliListFlag: false,
			allocatedDedicatedCliListFlag: false,
			allocatedClisIds : '',
			allocatedDedicatedClisIds : '',
			sizePerPage: 10,
			apiCliFlag: false,
			dedicatedCliFlag: false,
			activeUserId: null,
			loading: false,
			locationList: [],
			selectedLocations: [],
			activeLocationId: null,
			activeGroupId: null,
			activeModuleId: null,
			selectedUserAdditionalInfo: {},
			ttsFlag : false,
			moduleList : [],
			showAddTTSCreditModal: false,
			showRemoveTTSCreditModal : false,
			showSubmission: true
		}
	}
	componentDidMount() {
		this.getUsers();
		this.getCli();
		this.getDedicatedCli();
		this.getUserLocation()
		const savedSizePerPage = Cookies.get('userListsizePerPage');
		if (savedSizePerPage) {
			this.setState({ sizePerPage: Number(savedSizePerPage) });
		}
	}

	getDedicatedCli = () => {
		const apiPath = this.props.app.apiPath;
        const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/cli/'+userId)
			.then(res => {
				this.setState({
					dedicatedCliList: res
				})
			})
			.catch(err => { console.log('Error in fetching dedicated CLI List', err) });
	}

	getCli = () => {
		const apiPath = this.props.app.apiPath;
        const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/cli/api/'+userId)
			.then(res => {
				this.setState({
					cliList: res
				})
			})
			.catch(err => { console.log('Error in fetching CLI List', err) });
	}


	getUsers = () => {
		const apiPath = this.props.app.apiPath;
		const userId = this.props.app.userId;
		Fetcher.get(apiPath + '/api/obd/user/' + userId)
			.then(res => {
				this.setState({
					userList: res.users,
					adminCredits: res.adminCredits,
					showAddCreditModal: false,
					showRemoveCreditModal: false,
					showPwdModal: false,
					confirmDelete: false,
					confirmUpdateAccountType: false,
					showAddTTSCreditModal: false,
					showRemoveTTSCreditModal: false
				})
			})
			.catch(err => {
				console.log('Error in fetching Users List', err);
			});
	}

	confirmDelete = (status, userId) => {
		this.setState({
			confirmDelete: status,
			selectedUserId: userId
		});
	}

	toggleChange = (e, name) => {
		this.setState({
			[name]: !this.state[name],
		});
		if(name === "manualAllocation"){
			this.state.bulkAllocation = false
		}
		if( name === "manualDedicatedAllocation"){
			this.state.bulkDedicatedAllocation = false
		}
		if(name === "bulkAllocation"){
			this.state.manualAllocation = false
		}
		if( name === "bulkDedicatedAllocation"){
			this.state.manualDedicatedAllocation = false
		}
	}

	confirmSettingRemove = (status, subId,userId) => {
		this.setState({
			confirmRemoveSettingStatus: status,
			selectedSubId: subId,
			selectedSettingUserId : userId
		});
	}

	removeSetting = () => {
		const apiPath = this.props.app.apiPath;
		const subid = this.state.selectedSubId;
		const userID = this.state.selectedSettingUserId;
		console.log("Sub Id : "+subid);
		console.log("User Id : "+userID);
		if(subid === 0){
			this.state.confirmRemoveSettingStatus = false;
			alert("No Setting Found");
		}else{
			Fetcher.post(this.props.app.apiPath + '/api/obd/user/sub/config/'+userID+"/" + subid, { headers: { "Content-type": "application/json" }, method: 'DELETE'})
			.then(res => {
				this.state.confirmRemoveSettingStatus = false;
				Notify.notify(res.message);
				this.getUsers();
			})
			.catch(err => {
				console.log('Error while removing setting', err)
				Notify.notify('Error while removing setting');
			});
		}
		
	}

	allocateApiCli = (status, userId) => {
		this.setState({
			confirmAllocateApiCliUserId: status,
			selectedAllocateApiCliUserId: userId,
			manualAllocation : false,
			bulkAllocation: false,
			selectedLocations: []
		});

		const apiPath = this.props.app.apiPath;
        
		if(status){
		Fetcher.get(apiPath + '/api/obd/api/cli/allocated/' + userId)
			.then(res => {
				this.setState({
					allocatedCliList: res
				})
			})
			.catch(err => {
				console.log('Error in fetching Users List', err);
			});
		}

	}

	allocateDedicatedCli = (status, userId) => {
		this.setState({
			confirmAllocateDedicatedCliUserId: status,
			selectedAllocateDedicatedCliUserId: userId,
			manualDedicatedAllocation : false,
			bulkDedicatedAllocation: false,
			selectedLocations: []
		});

		const apiPath = this.props.app.apiPath;
        
		if(status){
		Fetcher.get(apiPath + '/api/obd/dedicated/cli/allocated/' + userId)
			.then(res => {
				this.setState({
					allocatedDedicatedCliList: res
				})
			})
			.catch(err => {
				console.log('Error in fetching Users List', err);
			});
		}

	}

	deleteUser = () => {
		const userId = this.state.selectedUserId;
		const data = {
			parent: this.props.app.userId,
		}

		return Fetcher.post(this.props.app.apiPath + '/api/obd/user/' + userId, { headers: { "Content-type": "application/json" }, method: 'DELETE', body: JSON.stringify(data) })
			.then(res => {
				Notify.notify(res.message);
				this.getUsers();
			})
			.catch(err => {
				console.log('Error in Deleting User', err)
				Notify.notify('Error in Deleting User');
			});
	}
	showPassword = (userId) => {
		const apiPath = this.props.app.apiPath;
		Fetcher.get(apiPath + '/api/obd/pwd/' + userId)
			.then(res => {
				alert(res.password);
			})
			.catch(err => {
				console.log('Error in fetching Password', err);
				Notify.notify('Error in fetching Password');
			});
		
	}
	addCredits = (event) => {
		event.preventDefault();
		if (this.state.creditsToAdd <= 0) {
			Notify.notify('Recharge Amount cannot be less than or equal to 0');
			return;
		}
		const data = {
			userId: this.state.selectedUserId,
			parent: this.props.app.userId,
			credits: this.state.creditsToAdd,
		}
		return Fetcher.post(this.props.app.apiPath + '/api/obd/credits/add', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Recharge Failed.');
				} else {
					Notify.notify(res.message);
				}
				this.setState({
					creditsToAdd: 0
				})
				this.getUsers();
				//window.location.reload(true);
			})
			.catch(err => {
				console.log('Error in Recharge', err)
				Notify.notify('Error in Recharge');
			});
	}


	addTTSCredits = (event) => {
		event.preventDefault();
		if (this.state.creditsToAdd <= 0) {
			Notify.notify('Credits cannot be less than or equal to 0');
			return;
		}
		const data = {
			userId: this.state.selectedUserId,
			parent: this.props.app.userId,
			credits: this.state.creditsToAdd,
		}
		// this.props.app.apiPath
		return Fetcher.post(this.props.app.apiPath + '/api/obd/tts/credits/add', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Recharge Failed.');
				} else {
					Notify.notify(res.message);
				}
				this.setState({
					creditsToAdd: 0
				})
				window.location.reload(true);
			})
			.catch(err => {
				console.log('Error in Recharge', err)
				Notify.notify('Error in Recharge');
			});
	}

	removeCredits = (event) => {
		event.preventDefault();
		if (this.state.creditsToRemove <= 0) {
			Notify.notify('Recharge amount cannot be less than or equal to 0');
			return;
		}
		const data = {
			userId: this.state.selectedUserId,
			parent: this.props.app.userId,
			credits: this.state.creditsToRemove,
		}
		return Fetcher.post(this.props.app.apiPath + '/api/obd/credits/remove', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Recharge Failed');
				} else {
					Notify.notify(res.message);
				}
				this.setState({
					creditsToRemove: 0
				})
				this.getUsers();
				//window.location.reload(true);
			})
			.catch(err => {
				console.log('Error in Recharge', err)
				Notify.notify('Error in Recharge');
			});
	}

	removeTTSCredits = (event) => {
		event.preventDefault();
		if (this.state.creditsToRemove <= 0) {
			Notify.notify('Credits cannot be less than or equal to 0');
			return;
		}
		const data = {
			userId: this.state.selectedUserId,
			parent: this.props.app.userId,
			credits: this.state.creditsToRemove,
		}
		// this.props.app.apiPath
		return Fetcher.post(this.props.app.apiPath + '/api/obd/tts/credits/remove', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Recharge Failed');
				} else {
					Notify.notify(res.message);
				}
				this.setState({
					creditsToRemove: 0
				})
				window.location.reload(true);
			})
			.catch(err => {
				console.log('Error in Recharge', err)
				Notify.notify('Error in Recharge');
			});
	}

	resetPassword = (event) => {
		event.preventDefault();
		if (this.state.confirmPassword !== this.state.password) {
			Notify.notify('Password & Confim Password don\'t match');
			return;
		}
		const data = {
			userId: this.state.selectedUserId,
			parent: this.props.app.userId,
			password: this.state.password,
		}
		return Fetcher.post(this.props.app.apiPath + '/api/obd/pwd/reset', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Reset Password Failed');
				} else {
					Notify.notify(res.message);
				}
				this.getUsers();
			})
			.catch(err => {
				console.log('Error in Reset Password', err)
				Notify.notify('Error in Reset Password');
			});
	}

	setvalue = (e) => {
		this.setState({
			[e.target.name]: e.target.value,
		});
	}

	setMessageShow = (status) => {
		this.setState({
			showAddCreditModal: false,
			showRemoveCreditModal: false,
			showPwdModal: false,
			showAddTTSCreditModal: false,
			showRemoveTTSCreditModal: false,
		});
	}

	showAddCreditModal = (userId) => {
		this.setState({
			showAddCreditModal: true,
			selectedUserId: userId
		});
	}
	showRemoveCreditModal = (userId) => {
		this.setState({
			showRemoveCreditModal: true,
			selectedUserId: userId
		});
	}

	showRemoveTTSCreditModal = (userId) => {
        
		 this.setState({
			ttsFlag : false
		})

       Fetcher.get(this.props.app.apiPath + '/api/obd/module/list/' + userId)
			.then(res => {
				this.setState({
					moduleList: res
				})
				if (!isEmpty(this.state.moduleList) && (!('error' in this.state.moduleList))) {
					this.state.moduleList.map((fileObj) => {
						
						if(fileObj.moduleName === 'TTS'){
                            this.state.ttsFlag = true;
							console.log("TTS Flag :"+this.state.ttsFlag);
							this.setState({
			                    showRemoveTTSCreditModal: true,
			                    selectedUserId: userId
		                    });
						}
					})
				}

				if(!this.state.ttsFlag){
					Notify.notify('Please Assign TTS Module first.');
			        return;
				}
			})
			.catch(err => {
				console.log('Error in fetching Module List', err);
			});
	}

	showAddTTSCreditModal = (userId) => {
        this.setState({
			ttsFlag : false
		})

       Fetcher.get(this.props.app.apiPath + '/api/obd/module/list/' + userId)
			.then(res => {
				this.setState({
					moduleList: res
				})
				if (!isEmpty(this.state.moduleList) && (!('error' in this.state.moduleList))) {
					this.state.moduleList.map((fileObj) => {
						
						if(fileObj.moduleName === 'TTS'){
                            this.state.ttsFlag = true;
							console.log("TTS Flag :"+this.state.ttsFlag);
							this.setState({
			                   showAddTTSCreditModal: true,
			                   selectedUserId: userId
		                   });
						}
					})
				}

				if(!this.state.ttsFlag){
					Notify.notify('Please Assign TTS Module first.');
			        return;
				}
			})
			.catch(err => {
				console.log('Error in fetching Module List', err);
			});
	}

	showPwdModal = (userId) => {
		this.setState({
			showPwdModal: true,
			selectedUserId: userId
		});
	}

	handleSelect = function (selectedItems) {
		const cliSelected = [];
		for (let i = 0; i < selectedItems.length; i++) {
			cliSelected.push(selectedItems[i].value);
		}
		this.setState({
			allocateApiCliId: cliSelected.toString()
		});
		console.log("Cli Ids : "+this.state.allocateApiCliId);
	}

	handleDedicatedSelect = function (selectedItems) {
		const cliSelected = [];
		for (let i = 0; i < selectedItems.length; i++) {
			cliSelected.push(selectedItems[i].value);
		}
		this.setState({
			allocateDedicatedCliId: cliSelected.toString()
		});
		console.log("Cli Ids : "+this.state.allocateDedicatedCliId);
	}

	handleCliSelect = function (selectedItems) {
		const cliSelected = [];
		for (let i = 0; i < selectedItems.length; i++) {
			cliSelected.push(selectedItems[i].value);
		}
		this.setState({
			allocatedClisIds: cliSelected.toString()
		});
		console.log("Cli Ids : "+this.state.allocatedClisIds);
	}
	handleDedicatedCliSelect = function (selectedItems) {
		const cliSelected = [];
		for (let i = 0; i < selectedItems.length; i++) {
			cliSelected.push(selectedItems[i].value);
		}
		this.setState({
			allocatedDedicatedClisIds: cliSelected.toString()
		});
		console.log("Cli Ids : "+this.state.allocatedDedicatedClisIds);
	}


    removeManualBulkCli = (allocateApiCliIdStr) => {
		const allocateApiCliIds = allocateApiCliIdStr.split(',');
		const cliList = this.state.cliList.filter(val => {
			return !Boolean( allocateApiCliIds.includes(val.cli) );
		});
		this.setState({ cliList: cliList })
	}


	submitAllocateCli = (event) => {
		event.preventDefault();
		console.log("Manual cli : "+this.state.manualAllocation);
		console.log("Buld cli : "+this.state.bulkAllocation);
		console.log("CliIds : "+this.state.allocateApiCliId);
		console.log("Cli Id : "+this.state.cliId);
		console.log("Range : "+this.state.cliRange);

		if (this.state.cliRange !== '') {
			if(Number(this.state.cliRange <=0)){
			Notify.notify('Cli Range can not be negative or 0');
			return;
			}
		}
		let data = {};
		if(this.state.manualAllocation){
		    data = {
			      userId: this.state.selectedAllocateApiCliUserId.toString(),
			      allocationType: "manual",
			      cli: this.state.allocateApiCliId
		   }
	    }else if(this.state.bulkAllocation){
			data = {
			      userId: this.state.selectedAllocateApiCliUserId.toString(),
			      allocationType: "bulk",
			      cli: this.state.cliId,
				  cliRange: this.state.cliRange
		   }
		}
		console.log(JSON.stringify(data));

		return Fetcher.post(this.props.app.apiPath + '/api/obd/api/cli/allocate', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					this.state.confirmAllocateApiCliUserId = false
					Notify.notify('Allocation Failed');
				} else {
					Notify.notify(res.message);
					// if(this.state.manualAllocation) this.removeManualBulkCli(this.state.allocateApiCliId);
					// else if(this.state.bulkAllocation) this.removeManualBulkCli(this.state.cliId);
				}
				this.getCli();
				Fetcher.get(this.props.app.apiPath + '/api/obd/api/cli/allocated/' + this.state.selectedAllocateApiCliUserId)
				.then(res => {
					this.setState({
						allocatedCliList: res
					})
				})
				.catch(err => {
					console.log('Error in fetching allocated CLI list', err);
				});
			})
			.catch(err => {
				console.log('Error in Allocate Cli', err)
				Notify.notify('Error in Allocate Cli');
			});

	}

	submitAllocateDedicatedCli = (event) => {
		event.preventDefault();
		console.log("Manual cli : "+this.state.manualDedicatedAllocation);
		console.log("Buld cli : "+this.state.bulkDedicatedAllocation);
		console.log("CliIds : "+this.state.allocateDedicatedCliId);
		console.log("Cli Id : "+this.state.dedicatedCliId);
		console.log("Range : "+this.state.dedicatedCliRange);

		if (this.state.dedicatedCliRange !== '') {
			if(Number(this.state.dedicatedCliRange <=0)){
			Notify.notify('Cli Range can not be negative or 0');
			return;
			}
		}
		let data = {};
		if(this.state.manualDedicatedAllocation){
		    data = {
			      userId: this.state.selectedAllocateDedicatedCliUserId.toString(),
			      allocationType: "manual",
			      cli: this.state.allocateDedicatedCliId
		   }
	    }else if(this.state.bulkDedicatedAllocation){
			data = {
			      userId: this.state.selectedAllocateDedicatedCliUserId.toString(),
			      allocationType: "bulk",
			      cli: this.state.dedicatedCliId,
				  cliRange: this.state.dedicatedCliRange
		   }
		}
		console.log(JSON.stringify(data));

		return Fetcher.post(this.props.app.apiPath + '/api/obd/dedicated/cli/allocate', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					this.state.confirmAllocateDedicatedCliUserId = false
					Notify.notify('Allocation Failed');
				} else {
					
					Notify.notify(res.message);
					// if(this.state.manualDedicatedAllocation) this.removeManualBulkCli(this.state.allocateDedicatedCliId);
					// else if(this.state.bulkDedicatedAllocation) this.removeManualBulkCli(this.state.dedicatedCliId);
				}
				this.getDedicatedCli();
				Fetcher.get(this.props.app.apiPath + '/api/obd/dedicated/cli/allocated/' + this.state.selectedAllocateDedicatedCliUserId)
					.then(res => {
						this.setState({
							allocatedDedicatedCliList: res
						})
					})
					.catch(err => {
						console.log('Error in fetching allocated CLI List', err);
					});
			})
			.catch(err => {
				console.log('Error in Allocate Cli', err)
				Notify.notify('Error in Allocate Cli');
			});

	}

	removeAllocateCli = (event) => {
		event.preventDefault();
		console.log("Here Remove");
		console.log("Selected Clis : "+this.state.allocatedClisIds);
		const data = {
			userId: this.state.selectedAllocateApiCliUserId.toString(),
			cliIds: this.state.allocatedClisIds
		}

		
		return Fetcher.post(this.props.app.apiPath + '/api/obd/api/remove/cli', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Remove Cli Failed');
				} else {
					Notify.notify(res.message);
				}
				this.getCli();
				Fetcher.get(this.props.app.apiPath + '/api/obd/api/cli/allocated/' + this.state.selectedAllocateApiCliUserId)
				.then(res => {
					this.setState({
						allocatedCliList: res
					})
				})
				.catch(err => {
					console.log('Error in fetching allocated CLI list', err);
				});
			})
			.catch(err => {
				console.log('Error in Removing Cli', err)
				Notify.notify('Error in Removing Cli');
			});

	}

	removeAllocateDedicatedCli = (event) => {
		event.preventDefault();
		console.log("Here Remove");
		console.log("Selected Clis : "+this.state.allocatedDedicatedClisIds);
		const data = {
			userId: this.state.selectedAllocateDedicatedCliUserId.toString(),
			cliIds: this.state.allocatedDedicatedClisIds
		}

		
		return Fetcher.post(this.props.app.apiPath + '/api/obd/dedicated/remove/cli', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Remove Cli Failed');
				} else {
					Notify.notify(res.message);
				}
				this.getDedicatedCli();
				Fetcher.get(this.props.app.apiPath + '/api/obd/dedicated/cli/allocated/' + this.state.selectedAllocateDedicatedCliUserId)
					.then(res => {
						this.setState({
							allocatedDedicatedCliList: res
						})
					})
					.catch(err => {
						console.log('Error in fetching allocated CLI List', err);
					});
			})
			.catch(err => {
				console.log('Error in Removing Cli', err)
				Notify.notify('Error in Removing Cli');
			});

	}

	handleSizePerPageChange = (newSizePerPage) => {
		this.setState({ sizePerPage: newSizePerPage });
		Cookies.set('userListsizePerPage', newSizePerPage);
	}

	getUserModuleList = (userId) => {
		if(this.state.loading === true){
			return;
		}
		this.setState({
			apiCliFlag: false,
			dedicatedCliFlag: false,
			activeUserId: userId,
			loading: true
		})
		const apiPath = this.props.app.apiPath;
		Fetcher.get(apiPath + '/api/obd/module/list/' + userId)
			.then(res => {
				this.setState({
					moduleList: res,
					loading: false
				})
				if (!isEmpty(this.state.moduleList) && (!('error' in this.state.moduleList))) {
					this.state.moduleList.map((fileObj) => {
						if (fileObj.moduleName === 'Api') {
							this.setState({ apiCliFlag: true });
						} else if (fileObj.moduleName === 'Dedicated Cli') {
							this.setState({ dedicatedCliFlag: true });
						}
					})
				}
			})
			.catch(err => { 
				console.log('Error in fetching Modules', err) 
				this.setState({
					loading: false
				})
			});
	}

	handleDropdownToggle = (isOpen) => {
		if (!isOpen) {
			this.setState({ activeUserId: null });
		}
	};

	getUserLocation = () => {
		const userId = this.props.app.userId;
		this.setState({
			locationList: []
		})
		const apiPath = this.props.app.apiPath;
		Fetcher.get(apiPath + '/api/obd/locations')
			.then(res => {
				this.setState({
					locationList: res
				})
			})
			.catch(err => { 
				console.log('Error in fetching Location List', err) 
			});
	}

	handleLocationSelect = (selectedItems) => {
		let locationsSelected = [];
		const selectedValues = Array.from(selectedItems).map(item => item.value);
	
		if (selectedValues.includes("Select All")) {
            if (selectedValues.length === 1) {
                // Only "Select All" is selected, select all locations
                locationsSelected = this.state.locationList.map(location => location.locationName);
                locationsSelected.unshift("Select All"); // Add "Select All" to the beginning
            } else {
                // "Select All" and other locations are selected, deselect "Select All"
                locationsSelected = selectedValues.filter(value => value !== "Select All");
            }
        } else {
            locationsSelected = selectedValues;
        }
	
		this.setState({
			selectedLocations: locationsSelected
		});
	};

	getUserAdditionalInfo = (userId, type) => {
		if(this.state.loading === true){
			return;
		}
		this.setState({
			...(type === 'location' && { activeLocationId: userId }),
			...(type === 'group' && { activeGroupId: userId }),
			...(type === 'module' && { activeModuleId: userId }),
			loading: true
		})
		const apiPath = this.props.app.apiPath;
		Fetcher.get(apiPath + '/api/obd/user/profile/' + userId)
			.then(res => {
				this.setState({
					selectedUserAdditionalInfo: res,
					loading: false
				})
			})
			.catch(err => { 
				console.log('Error in fetching Location list', err) 
				this.setState({
					loading: false
				})
			});
	}

	handleDropdownToggleAction = (isOpen, type) => {
		if (!isOpen) {
			if (type === 'location') {
				this.setState({ activeLocationId: null });
			} else if (type === 'group') {
				this.setState({ activeGroupId: null });
			} else if (type === 'module') {
				this.setState({ activeModuleId: null });
			}
		}
	};

	confirmUpdateAccountType = (status, userId, accountType) => {
		this.setState({
			confirmUpdateAccountType: status,
			selectedUserId: userId,
			selectedUserccountType: accountType,
		});
	}
	toggleAccountType = () => {
		const userId = this.state.selectedUserId;
		const accountType = this.state.selectedUserccountType;
		const data = {
			userId: userId,
			accountType: accountType === 0 ? 1 : 0
		}
		return Fetcher.post(this.props.app.apiPath + '/api/obd/change/accountType', { headers: { "Content-type": "application/json" }, method: 'POST', body: JSON.stringify(data) })
			.then(res => {
				if (res.message === 'Parameters Incorrect') {
					Notify.notify('Error in updating account type');
				} else {
					Notify.notify(res.message);
				}
				this.getUsers();
			})
			.catch(err => {
				console.log('Error in updating account type', err)
				Notify.notify('Error in updating account type');
			})
			.finally(() => {
				this.setState({ confirmRemoveAllocatedCli: false })
			});
	}

	getProfile = (id) => {

		Fetcher.get(this.props.app.apiPath + '/api/obd/module/list/' + id)
			.then(res => {
				this.setState({
					moduleList: res
				})
				if (!isEmpty(this.state.moduleList) && (!('error' in this.state.moduleList))) {
					this.state.moduleList.map((fileObj) => {
						
						if(fileObj.moduleName === 'TTS'){
                            this.state.ttsFlag = true;
							console.log("TTS Flag :"+this.state.ttsFlag);
						}
					})
				}
			})
			.catch(err => {
				console.log('Error in fetching Module List', err);
			});
        return this.state.ttsFlag;
	}
	

	render() {

		const userList = this.state.userList;
		var columns;
		if (this.props.app.role === 'ROLE_SADMIN') {
			columns = [
				{
					dataField: 'userId',
					text: 'User Id',
					sort: true,
					hidden: false
				},
				{
					dataField: 'username',
					text: 'Username',
					sort: true,
					searchable: true
				},
				{
					dataField: 'locationList',
					text: 'Location',
					sort: false,
					searchable: false
				},
				{
					dataField: 'groupList',
					text: 'Group',
					sort: false,
					searchable: false
				},
				{
					dataField: 'moduleList',
					text: 'Module',
					sort: false,
					searchable: false
				},
				{
					dataField: 'accountType',
					text: 'Account Type',
					sort: true,
					searchable: false,
					headerStyle: { width: '130px' },
				},
				{
					dataField: 'company',
					text: 'Company',
					sort: true,
					searchable: false
				}, {
					dataField: 'userType',
					text: 'Type',
					sort: true,
					searchable: false
				}, {
					dataField: 'credits',
					text: 'Balance(Rs)',
					sort: false,
					searchable: false
				},{
					dataField: 'planName',
					text: 'Voice Plan',
					sort: false,
					searchable: false
				},{
					dataField: 'parentUser',
					text: 'Parent',
					sort: false,
					searchable: false
				},{
					dataField: 'expiryDate',
					text: 'User Expiry',
					sort: false,
					searchable: false
				},{
					dataField: 'deleted',
					text: 'Status',
					sort: false,
					searchable: false
				},{
					dataField: 'action',
					text: 'Action',
					sort: false,
					searchable: false,
					headerStyle: { width: '130px' }
				},{
					dataField: 'allocateCLI',
					text: 'Allocate CLI',
					sort: false,
					searchable: false
				},];
		}
		else {
			columns = [
				{
					dataField: 'userId',
					text: 'User Id',
					sort: true,
					hidden: false
				},
				{
					dataField: 'username',
					text: 'Username',
					sort: true,
					searchable: true
				},
				{
					dataField: 'locationList',
					text: 'Location',
					sort: false,
					searchable: false
				},
				{
					dataField: 'groupList',
					text: 'Group',
					sort: false,
					searchable: false
				},
				{
					dataField: 'moduleList',
					text: 'Module',
					sort: false,
					searchable: false
				},
				{
					dataField: 'accountType',
					text: 'Account Type',
					sort: true,
					searchable: false,
					headerStyle: { width: '130px' },
				},
				{
					dataField: 'company',
					text: 'Company',
					sort: true,
					searchable: false
				}, {
					dataField: 'userType',
					text: 'Type',
					sort: true,
					searchable: false
				}, {
					dataField: 'credits',
					text: 'Balance(Rs)',
					sort: false,
					searchable: false
				},{
					dataField: 'planName',
					text: 'Voice Plan',
					sort: false,
					searchable: false
				},{
					dataField: 'parentUser',
					text: 'Parent',
					sort: false,
					searchable: false
				},{
					dataField: 'expiryDate',
					text: 'User Expiry',
					sort: false,
					searchable: false
				},{
					dataField: 'deleted',
					text: 'Status',
					sort: false,
					searchable: false
				},{
					dataField: 'action',
					text: 'Action',
					sort: false,
					searchable: false,
					headerStyle: { width: '130px' }
				},{
					dataField: 'allocateCLI',
					text: 'Allocate CLI',
					sort: false,
					searchable: false
				},];
		}
		const users = Array.isArray(userList) ? userList.map((user, index) => {
			if (this.props.app.role === 'ROLE_SADMIN') {
				let action = [];
				action.push(<Dropdown key={`${index}-dropdown`}>
					<Dropdown.Toggle className="btn-sm" variant="primary">Take Action</Dropdown.Toggle>
						<Dropdown.Menu>
					  		<Dropdown.Item key={`${index}-edit-user`} href={`${this.props.app.urlPrefix}/update-user/${user.userId}`}>Edit User</Dropdown.Item>
					  		<Dropdown.Item key={`${index}-add-credit`} onClick={(e) => { this.showAddCreditModal(user.userId) }} href="#">Add Recharge</Dropdown.Item>
							<Dropdown.Item key={`${index}-remove-credit`} onClick={(e) => { this.showRemoveCreditModal(user.userId) }} href="#">Remove Recharge</Dropdown.Item>
					  		<Dropdown.Item key={`${index}-reset-pwd`} onClick={(e) => { this.showPwdModal(user.userId) }} href="#">Reset Password</Dropdown.Item>
					  		<Dropdown.Item key={`${index}-delete-user`} onClick={(e) => { this.confirmDelete(true, user.userId) }} href="#">Block User</Dropdown.Item>
							<Dropdown.Item key={`${index}-pwd`} onClick={(e) => { this.showPassword(user.userId) }} href="#">Show Password</Dropdown.Item>
						    {/* <Dropdown.Item key={`${index}-sub-id`} onClick={(e) => { this.removeCutting(user.subId) }} href="#">Remove Cutting</Dropdown.Item> */}
					        <Dropdown.Item key={`${index}-sub-id`} onClick={(e) => { this.confirmSettingRemove(true, user.subId,user.userId) }} href="#">Remove Setting</Dropdown.Item>
						   <Dropdown.Item key={`${index}-add-tts-credit`} onClick={(e) => { this.showAddTTSCreditModal(user.userId) }} href="#">Add TTS Recharge</Dropdown.Item>
						   <Dropdown.Item key={`${index}-remove-tts-credit`} onClick={(e) => { this.showRemoveTTSCreditModal(user.userId) }} href="#">Remove TTS Recharge</Dropdown.Item>
						</Dropdown.Menu>
				  	</Dropdown>);
				/*let action = [<Link id="edit-btn" data-tooltip-content="Edit User Profile" key={`${index}-edit-user`} className="edit-user" to={`${this.props.app.urlPrefix}/update-user/${user.userId}`}><i className="fa fa-edit"></i></Link>];
				action.push(<a id="add-btn" data-tooltip-content="Add Credits" key={`${index}-add-credit`} className="add-credit" onClick={(e) => { this.showAddCreditModal(user.userId) }} href="#"><i className="fa fa-plus"></i></a>)
				action.push(<a data-tooltip-content="Reset Password" key={`${index}-reset-pwd`} className="reset-pwd" onClick={(e) => { this.showPwdModal(user.userId) }} href="#"><i className="fa fa-refresh"></i></a>)
				action.push(<a data-tooltip-content="Delete User" key={`${index}-delete-user`} className="delete-user" onClick={(e) => { this.confirmDelete(true, user.userId) }} href="#"><i className="fa fa-trash"></i></a>)*/
				let action2 = [<a className="btn btn-sm btn-success" key={`${index}-allocate-api-cli`}  onClick={(e) => { this.allocateApiCli(true, user.userId) }} href="#">Allocate</a>];
				let accountTypeAction = (
					<Dropdown key={`${index}-account-type`}>
						<Dropdown.Toggle size="sm" variant="success">
							{user.accountType === 0 ? "Promotional" : "Transactional"}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							<Dropdown.Item onClick={() => this.confirmUpdateAccountType(true,user.userId, user.accountType)}>
								{user.accountType === 0 ? "Change to Transactional" : "Change to Promotional"}
							</Dropdown.Item>
						</Dropdown.Menu>
					</Dropdown>
				);
				let allocateCLIAction;
				if(this.state.activeUserId !== user.userId){
					allocateCLIAction = (
						<Button onClick={() => this.getUserModuleList(user.userId)} variant="success">Allocate</Button>
					);
				} else {
					if (this.state.apiCliFlag && this.state.dedicatedCliFlag) {
						allocateCLIAction = (
							<Dropdown show={this.state.activeUserId === user.userId} onToggle={this.handleDropdownToggle}>
								<Dropdown.Toggle className="btn-sm" variant="success">Allocate</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => { this.allocateApiCli(true, user.userId) }} href="#">Allocate API CLI</Dropdown.Item>
									<Dropdown.Item onClick={() => { this.allocateDedicatedCli(true, user.userId) }} href="#">Allocate Dedicated CLI</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						);
					} else if (this.state.apiCliFlag) {
						allocateCLIAction = (
							<Dropdown show={this.state.activeUserId === user.userId} onToggle={this.handleDropdownToggle}>
								<Dropdown.Toggle className="btn-sm" variant="success">Allocate</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => { this.allocateApiCli(true, user.userId) }} href="#">Allocate API CLI</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						);
					} else if (this.state.dedicatedCliFlag) {
						allocateCLIAction = (
							<Dropdown show={this.state.activeUserId === user.userId} onToggle={this.handleDropdownToggle}>
								<Dropdown.Toggle className="btn-sm" variant="success">Allocate</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => { this.allocateDedicatedCli(true, user.userId) }} href="#">Allocate Dedicated CLI</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						);
					} else {
						allocateCLIAction = (
							<Dropdown show={this.state.activeUserId === user.userId} onToggle={this.handleDropdownToggle}>
								<Dropdown.Toggle className="btn-sm" variant="success">Allocate</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word' }} href={`${this.props.app.urlPrefix}/update-user/${user.userId}`}>Update user with relevant module</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						);
					}
				}
				let selectedLocationAction;
				if(this.state.activeLocationId !== user.userId){
					selectedLocationAction = (
						<Dropdown show={false}>
							<Dropdown.Toggle onClick={() => this.getUserAdditionalInfo(user.userId, 'location')} size="sm" variant="success">Location</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item>
									Location
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					);
				} else {
					selectedLocationAction = (
						<Dropdown show={this.state.activeLocationId === user.userId} onToggle={(isOpen) => this.handleDropdownToggleAction(isOpen, 'location')}>
							<Dropdown.Toggle className="btn-sm" variant="success">Location</Dropdown.Toggle>
							<Dropdown.Menu>
								{isEmpty(this.state.selectedUserAdditionalInfo.location) ? (
										<Dropdown.Item disabled>No Location found</Dropdown.Item>
									) : (
										this.state.selectedUserAdditionalInfo.location.map((location, index) => (
											<Dropdown.Item key={`location${index}`} disabled>
												{location.locationName}
											</Dropdown.Item>
										))
								)}
							</Dropdown.Menu>
						</Dropdown>
					);
				}
				let selectedGroupAction;
				if(this.state.activeGroupId !== user.userId){
					selectedGroupAction = (
						<Dropdown show={false}>
							<Dropdown.Toggle onClick={() => this.getUserAdditionalInfo(user.userId, 'group')} size="sm" variant="success">Group</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item>
									Group
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					);
				} else {
					selectedGroupAction = (
						<Dropdown show={this.state.activeGroupId === user.userId} onToggle={(isOpen) => this.handleDropdownToggleAction(isOpen, 'group')}>
							<Dropdown.Toggle className="btn-sm" variant="success">Group</Dropdown.Toggle>
							<Dropdown.Menu>
								{isEmpty(this.state.selectedUserAdditionalInfo.group) ? (
										<Dropdown.Item disabled>No Group found</Dropdown.Item>
									) : (
										this.state.selectedUserAdditionalInfo.group.map((group, index) => (
											<Dropdown.Item key={`group${index}`} disabled>
												{group.groupName}
											</Dropdown.Item>
										))
								)}
							</Dropdown.Menu>
						</Dropdown>
					);
				}
				let selectedModuleAction;
				if(this.state.activeModuleId !== user.userId){
					selectedModuleAction = (
						<Dropdown show={false}>
							<Dropdown.Toggle onClick={() => this.getUserAdditionalInfo(user.userId, 'module')} size="sm" variant="success">Module</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item>
									Module
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					);
				} else {
					selectedModuleAction = (
						<Dropdown show={this.state.activeModuleId === user.userId} onToggle={(isOpen) => this.handleDropdownToggleAction(isOpen, 'module')}>
							<Dropdown.Toggle className="btn-sm" variant="success">Module</Dropdown.Toggle>
							<Dropdown.Menu>
								{isEmpty(this.state.selectedUserAdditionalInfo.modules) ? (
										<Dropdown.Item disabled>No Module found</Dropdown.Item>
									) : (
										this.state.selectedUserAdditionalInfo.modules.map((module, index) => (
											<Dropdown.Item key={`module${index}`} disabled>
												{module.moduleName}
											</Dropdown.Item>
										))
								)}
							</Dropdown.Menu>
						</Dropdown>
					);
				}
				return {
					userId: user.userId,
					username: user.username,
					locationList: selectedLocationAction,
					groupList: selectedGroupAction,
					moduleList: selectedModuleAction,
					accountType: accountTypeAction,
					company: user.company,
					userType: user.userType,
					credits: user.credits,
					planName: user.planName,
					parentUser: user.parentUser,
					expiryDate: user.expiryDate,
					deleted: user.deleted  === 0 ? "Active" : "In-Active",
					action: action,
					allocateCLI: allocateCLIAction
				};
			}
			else {
				let action = [];
				action.push(<Dropdown>
					<Dropdown.Toggle className='btn-sm'>Take Action</Dropdown.Toggle>
						<Dropdown.Menu>
						<Dropdown.Item key={`${index}-edit-user`} href={`${this.props.app.urlPrefix}/update-user/${user.userId}`}>Edit User</Dropdown.Item>
					  		<Dropdown.Item key={`${index}-add-credit`} onClick={(e) => { this.showAddCreditModal(user.userId) }} href="#">Add Recharge</Dropdown.Item>
							<Dropdown.Item key={`${index}-add-credit`} onClick={(e) => { this.showRemoveCreditModal(user.userId) }} href="#">Remove Recharge</Dropdown.Item>
					  		<Dropdown.Item key={`${index}-reset-pwd`} onClick={(e) => { this.showPwdModal(user.userId) }} href="#">Reset Password</Dropdown.Item>
					  		<Dropdown.Item key={`${index}-delete-user`} onClick={(e) => { this.confirmDelete(true, user.userId) }} href="#">Block User</Dropdown.Item>
						    <Dropdown.Item key={`${index}-add-tts-credit`} onClick={(e) => { this.showAddTTSCreditModal(user.userId) }} href="#">Add TTS Recharge</Dropdown.Item>
						   <Dropdown.Item key={`${index}-remove-tts-credit`} onClick={(e) => { this.showRemoveTTSCreditModal(user.userId) }} href="#">Remove TTS Recharge</Dropdown.Item>
						</Dropdown.Menu>
				  	</Dropdown>);
				/*let action = [<Link data-tip="Edit User Profile" key={`${index}-edit-user`} className="edit-user" to={`${this.props.app.urlPrefix}/update-user/${user.userId}`}><i className="fa fa-edit"></i></Link>];
				action.push(<a data-tip="Add Credits" key={`${index}-add-credit`} className="add-credit" onClick={(e) => { this.showAddCreditModal(user.userId) }} href="#"><i className="fa fa-plus"></i></a>)
				action.push(<a data-tip="Reset Password" key={`${index}-reset-pwd`} className="reset-pwd" onClick={(e) => { this.showPwdModal(user.userId) }} href="#"><i className="fa fa-refresh"></i></a>)
				action.push(<a data-tip="Delete User" key={`${index}-delete-user`} className="delete-user" onClick={(e) => { this.confirmDelete(true, user.userId) }} href="#"><i className="fa fa-trash"></i></a>)*/
				let action2 = [<a className="btn btn-sm btn-success" key={`${index}-allocate-api-cli`}  onClick={(e) => { this.allocateApiCli(true, user.userId) }} href="#">Allocate</a>];
				let accountTypeAction = (
					<Dropdown key={`${index}-account-type`}>
						<Dropdown.Toggle size="sm" variant="success">
							{user.accountType === 0 ? "Promotional" : "Transactional"}
						</Dropdown.Toggle>
						<Dropdown.Menu>
							{(this.props.app.role === 'ROLE_SADMIN') && <>
							<Dropdown.Item onClick={() => this.confirmUpdateAccountType(true, user.userId, user.accountType)}>
								{user.accountType === 0 ? "Change to Transactional" : "Change to Promotional"}
							</Dropdown.Item>
							</>}
						</Dropdown.Menu>
					</Dropdown>
				);
				let allocateCLIAction;
				if(this.state.activeUserId !== user.userId){
					allocateCLIAction = (
						<Button onClick={() => this.getUserModuleList(user.userId)} variant="success">Allocate</Button>
					);
				} else {
					if (this.state.apiCliFlag && this.state.dedicatedCliFlag) {
						allocateCLIAction = (
							<Dropdown show={this.state.activeUserId === user.userId} onToggle={this.handleDropdownToggle}>
								<Dropdown.Toggle className="btn-sm" variant="success">Allocate</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => { this.allocateApiCli(true, user.userId) }} href="#">Allocate API CLI</Dropdown.Item>
									<Dropdown.Item onClick={() => { this.allocateDedicatedCli(true, user.userId) }} href="#">Allocate Dedicated CLI</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						);
					} else if (this.state.apiCliFlag) {
						allocateCLIAction = (
							<Dropdown show={this.state.activeUserId === user.userId} onToggle={this.handleDropdownToggle}>
								<Dropdown.Toggle className="btn-sm" variant="success">Allocate</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => { this.allocateApiCli(true, user.userId) }} href="#">Allocate API CLI</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						);
					} else if (this.state.dedicatedCliFlag) {
						allocateCLIAction = (
							<Dropdown show={this.state.activeUserId === user.userId} onToggle={this.handleDropdownToggle}>
								<Dropdown.Toggle className="btn-sm" variant="success">Allocate</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item onClick={() => { this.allocateDedicatedCli(true, user.userId) }} href="#">Allocate Dedicated CLI</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						);
					} else {
						allocateCLIAction = (
							<Dropdown show={this.state.activeUserId === user.userId} onToggle={this.handleDropdownToggle}>
								<Dropdown.Toggle className="btn-sm" variant="success">Allocate</Dropdown.Toggle>
								<Dropdown.Menu>
									<Dropdown.Item style={{ whiteSpace: 'normal', wordWrap: 'break-word' }} href={`${this.props.app.urlPrefix}/update-user/${user.userId}`}>Update user with relevant module</Dropdown.Item>
								</Dropdown.Menu>
							</Dropdown>
						);
					}
				}
				let selectedLocationAction;
				if(this.state.activeLocationId !== user.userId){
					selectedLocationAction = (
						<Dropdown show={false}>
							<Dropdown.Toggle onClick={() => this.getUserAdditionalInfo(user.userId, 'location')} size="sm" variant="success">Location</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item>
									Location
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					);
				} else {
					selectedLocationAction = (
						<Dropdown show={this.state.activeLocationId === user.userId} onToggle={(isOpen) => this.handleDropdownToggleAction(isOpen, 'location')}>
							<Dropdown.Toggle className="btn-sm" variant="success">Location</Dropdown.Toggle>
							<Dropdown.Menu>
								{isEmpty(this.state.selectedUserAdditionalInfo.location) ? (
										<Dropdown.Item disabled>No Location found</Dropdown.Item>
									) : (
										this.state.selectedUserAdditionalInfo.location.map((location, index) => (
											<Dropdown.Item key={`location${index}`} disabled>
												{location.locationName}
											</Dropdown.Item>
										))
								)}
							</Dropdown.Menu>
						</Dropdown>
					);
				}
				let selectedGroupAction;
				if(this.state.activeGroupId !== user.userId){
					selectedGroupAction = (
						<Dropdown show={false}>
							<Dropdown.Toggle onClick={() => this.getUserAdditionalInfo(user.userId, 'group')} size="sm" variant="success">Group</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item>
									Group
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					);
				} else {
					selectedGroupAction = (
						<Dropdown show={this.state.activeGroupId === user.userId} onToggle={(isOpen) => this.handleDropdownToggleAction(isOpen, 'group')}>
							<Dropdown.Toggle className="btn-sm" variant="success">Group</Dropdown.Toggle>
							<Dropdown.Menu>
								{isEmpty(this.state.selectedUserAdditionalInfo.group) ? (
										<Dropdown.Item disabled>No Group found</Dropdown.Item>
									) : (
										this.state.selectedUserAdditionalInfo.group.map((group, index) => (
											<Dropdown.Item key={`group${index}`} disabled>
												{group.groupName}
											</Dropdown.Item>
										))
								)}
							</Dropdown.Menu>
						</Dropdown>
					);
				}
				let selectedModuleAction;
				if(this.state.activeModuleId !== user.userId){
					selectedModuleAction = (
						<Dropdown show={false}>
							<Dropdown.Toggle onClick={() => this.getUserAdditionalInfo(user.userId, 'module')} size="sm" variant="success">Module</Dropdown.Toggle>
							<Dropdown.Menu>
								<Dropdown.Item>
									Module
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					);
				} else {
					selectedModuleAction = (
						<Dropdown show={this.state.activeModuleId === user.userId} onToggle={(isOpen) => this.handleDropdownToggleAction(isOpen, 'module')}>
							<Dropdown.Toggle className="btn-sm" variant="success">Module</Dropdown.Toggle>
							<Dropdown.Menu>
								{isEmpty(this.state.selectedUserAdditionalInfo.modules) ? (
										<Dropdown.Item disabled>No Module found</Dropdown.Item>
									) : (
										this.state.selectedUserAdditionalInfo.modules.map((module, index) => (
											<Dropdown.Item key={`module${index}`} disabled>
												{module.moduleName}
											</Dropdown.Item>
										))
								)}
							</Dropdown.Menu>
						</Dropdown>
					);
				}
				return {
					userId: user.userId,
					username: user.username,
					locationList: selectedLocationAction,
					groupList: selectedGroupAction,
					moduleList: selectedModuleAction,
					accountType: accountTypeAction,
					company: user.company,
					userType: user.userType,
					credits: user.credits,
					planName: user.planName,
					parentUser: user.parentUser,
					expiryDate: user.expiryDate,
					deleted: user.deleted  === 0 ? "Active" : "In-Active",
					action: action,
					allocateCLI: allocateCLIAction
				};
			}
		}) : []
		const { SearchBar, ClearSearchButton } = Search;
		const { ExportCSVButton } = CSVExport;

		const defaultSorted = [{
			dataField: 'userId',
			order: 'desc'
		}];

		const paginationOption = {
			custom: true,
			totalSize: users.length,
			sizePerPage: this.state.sizePerPage,
			sizePerPageList: [10, 25, 50, 100],
			onSizePerPageChange: this.handleSizePerPageChange
		};

		//const cliList = cloneDeep(this.state.cliList);
		let cliDropdown = '';
		if (!isEmpty(this.state.cliList) && (!('error' in this.state.cliList))) {
			cliDropdown = Array.isArray(this.state.cliList) ? this.state.cliList.map((obj, index) => {
				return <option value={obj.cli} key={obj.cli} >{obj.cli}</option>
			}) : []

		} else {
			cliDropdown = <option value="" >No Cli found</option>
		}

		let dedicatedCliDropdown = '';
		const selectedLocations = this.state.selectedLocations;
		if (!isEmpty(this.state.dedicatedCliList) && (!('error' in this.state.dedicatedCliList))) {
            
			let dcli =[];
			for(let i=0;i<this.state.dedicatedCliList.length;i++){
				      
                   if(this.state.dedicatedCliList[i].cliStatus !=2){
					dcli.push(this.state.dedicatedCliList[i]);
				   }
			}

			dedicatedCliDropdown = Array.isArray(dcli) ? dcli.map((obj, index) => {
				
				const isHidden = selectedLocations.length > 0 && !selectedLocations.includes(obj.locationName);
				return <option value={obj.cli} key={obj.cliId} hidden={isHidden} >{obj.cli} ({obj.dialerName})</option>
				
			}) : []

		} else {
			dedicatedCliDropdown = <option value="" >No Cli found</option>
		}

		let locationDropdownOptions = '';
		if (!isEmpty(this.state.locationList)) {
			locationDropdownOptions = this.state.locationList.map((location, index) => (
				<option value={location.locationName} key={`location${index}`}>
					{location.locationName}
				</option>
			));
		} else {
			locationDropdownOptions = <option value="">No locations found</option>;
		}


		const allocatedcliList = cloneDeep(this.state.allocatedCliList);
		let allocatedCliDropdown = '';
		if(allocatedcliList.length >0){
			this.state.allocatedCliListFlag = true;
		}
		if (!isEmpty(allocatedcliList) && (!('error' in allocatedcliList))) {
			allocatedCliDropdown = Array.isArray(allocatedcliList) ? allocatedcliList.map((obj, index) => {
				return <option value={obj.cliId} key={obj.cliId} >{obj.cli}</option>
			}) : []

		} else {
			allocatedCliDropdown = <option value="" >No Allocated Cli found</option>
		}

		const allocatedDedicatedcliList = cloneDeep(this.state.allocatedDedicatedCliList);
		let allocatedDedicatedCliDropdown = '';
		if(allocatedDedicatedcliList.length >0){
			this.state.allocatedDedicatedCliListFlag = true;
		}
		if (!isEmpty(allocatedDedicatedcliList) && (!('error' in allocatedDedicatedcliList))) {
			allocatedDedicatedCliDropdown = Array.isArray(allocatedDedicatedcliList) ? allocatedDedicatedcliList.map((obj, index) => {
				return <option value={obj.cliId} key={obj.cliId} >{obj.cli}({obj.dialerName})</option>
			}) : []

		} else {
			allocatedDedicatedCliDropdown = <option value="" >No Allocated Cli found</option>
		}

		return (
			<Card>
				<Card.Header>Users List</Card.Header>
				{/*<Card.Header>Users List<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span></Card.Header>*/}

				<Card.Body>
					<div className="user-list-table-cont">
						{<PaginationProvider pagination={paginationFactory(paginationOption)}>
							{
								({
									paginationProps,
									paginationTableProps
								}) => (
									<ToolkitProvider
										keyField="userId"
										data={users}
										columns={columns}
										search
										bootstrap4
										exportCSV={ {
											fileName: 'users.csv'
										  } }
									>
										{
											props => (
												<div>
													Show <SizePerPageDropdownStandalone className="search-dropdown" {...paginationProps} /> items
													<span className="search-cont"><SearchBar  {...props.searchProps} /></span>
													<ClearSearchButton className="btn-primary btn-round" {...props.searchProps} />
													<ExportCSVButton className="export-csv btn-primary ml-2" {...props.csvProps}>Export to CSV</ExportCSVButton>
													{/*<span>&nbsp;&nbsp;&nbsp;</span><span className="float-none avail-credit-cont"><span className="credits-avail">Available Credits: {this.state.adminCredits}</span></span>*/}
													<Link className="float-right btn btn-default btn-primary" to={`${this.props.app.urlPrefix}/create-user`}>Add User</Link>
													<hr/>
													<BootstrapTable defaultSorted={defaultSorted} hover bordered={true} wrapperClasses="table-responsive" classes="userlist-table"  {...props.baseProps}  {...paginationTableProps} />
													<PaginationListStandalone {...paginationProps} />
												</div>
											)
										}
									</ToolkitProvider>
								)
							}
						</PaginationProvider>}
					</div>
					<Modal size="md" show={this.state.showAddCreditModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Add Recharge Amount
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.addCredits}>
								<Row>
									<Col sm={12}>
										<Form.Group className="form-group" >
											<Form.Control required name="creditsToAdd" type="number" placeholder="Recharge Amount(Rs)" onChange={e => this.setvalue(e)} value={this.state.creditsToAdd} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Add Recharge</Button>
							</Form>
						</Modal.Body>
					</Modal>


                    <Modal size="md" show={this.state.showAddTTSCreditModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Add TTS Recharge Credits
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.addTTSCredits}>
								<Row>
									<Col sm={12}>
										<Form.Group className="form-group" >
											<Form.Control required name="creditsToAdd" type="number" placeholder="Recharge Amount(Rs)" onChange={e => this.setvalue(e)} value={this.state.creditsToAdd} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Add Recharge Credits</Button>
							</Form>
						</Modal.Body>
					</Modal>


					<Modal size="md" show={this.state.showRemoveCreditModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Remove Recharge Amount
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.removeCredits}>
								<Row>
									<Col sm={12}>
										<Form.Group className="form-group" >
											<Form.Control required name="creditsToRemove" type="number" placeholder="Recharge Amount(Rs)" onChange={e => this.setvalue(e)} value={this.state.creditsToRemove} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Remove Recharge</Button>
							</Form>
						</Modal.Body>
					</Modal>


					<Modal size="md" show={this.state.showRemoveTTSCreditModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Remove Recharge Credits
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.removeTTSCredits}>
								<Row>
									<Col sm={12}>
										<Form.Group className="form-group" >
											<Form.Control required name="creditsToRemove" type="number" placeholder="Recharge Amount(Rs)" onChange={e => this.setvalue(e)} value={this.state.creditsToRemove} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Remove Recharge</Button>
							</Form>
						</Modal.Body>
					</Modal>

					<Modal size="md" show={this.state.showPwdModal} onHide={() => this.setMessageShow(false)}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Reset Password
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.resetPassword}>
								<Row>
									<Col sm={6}>
										<Form.Group className="form-group" >
											<Form.Control required name="password" type="password" placeholder="Password" onChange={e => this.setvalue(e)} value={this.state.password} />
										</Form.Group>
									</Col>
								</Row>
								<Row>
									<Col sm={6}>
										<Form.Group className="form-group" >
											<Form.Control required name="confirmPassword" type="password" placeholder="Confirm Password" onChange={e => this.setvalue(e)} value={this.state.confirmPassword} />
										</Form.Group>
									</Col>
								</Row>
								<Button variant="primary" type="submit">Reset Password</Button>
							</Form>
						</Modal.Body>
					</Modal>

					<Modal size="md" show={this.state.confirmDelete} onHide={() => this.confirmDelete(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Block User
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to block this user?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmDelete(false, '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.deleteUser} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>

					<Modal size="md" show={this.state.confirmUpdateAccountType} onHide={() => this.confirmUpdateAccountType(false, '', '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Change Account Type
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to change account type of this user?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmUpdateAccountType(false, '', '')} variant="secondary">CANCEL</Button>
							<Button onClick={this.toggleAccountType} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>

					<Modal size="md" show={this.state.confirmRemoveSettingStatus} onHide={() => this.confirmSettingRemove(false, '','')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Remove Setting
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							Are you sure you want to Remove Setting?
						</Modal.Body>
						<Modal.Footer>
							<Button onClick={() => this.confirmSettingRemove(false, '','')} variant="secondary">CANCEL</Button>
							<Button onClick={this.removeSetting} variant="primary">YES</Button>
						</Modal.Footer>
					</Modal>

					<Modal size="md" show={this.state.confirmAllocateApiCliUserId} onHide={() => this.allocateApiCli(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Allocate Api CLI
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.submitAllocateCli}>
								<Row>
									<Col >
									<Form.Group className="form-group" >
											<Form.Check checked={this.state.manualAllocation} onChange={( e ) => this.toggleChange( e, 'manualAllocation' )} custom inline name="manualAllocation" label="Manual Allocation" id="manualAllocation" type="checkbox" />
									</Form.Group>
                                    </Col>
									<Col>
									<Form.Group className="form-group" >
											<Form.Check checked={this.state.bulkAllocation} onChange={( e ) => this.toggleChange( e, 'bulkAllocation' )} custom inline name="bulkAllocation" label="Bulk Allocation" id="bulkAllocation" type="checkbox" />
									</Form.Group>
									</Col>
								</Row>
								<Row>
								{(this.state.manualAllocation) && 
								    <Col>
									<Form.Group className="form-group channels control-label">
											<Form.Label>Select Multiple Cli</Form.Label>
											<Form.Select className="dd" name="allocateApiCliId" onChange={(e) => { this.handleSelect(e.target.selectedOptions) }} multiple as="select" htmlSize="8">
												{cliDropdown}
											</Form.Select>
										</Form.Group>
									</Col> 
								}
								</Row>
								<Row>
								{(this.state.bulkAllocation) && 
								    <Col sm={6}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" required name="cliId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Cli</option>
												{cliDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								}
								</Row>
								<Row>
								{(this.state.bulkAllocation) && 
								    
									<Col sm={6}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="cliRange" type="number" placeholder="Range" onChange={e => this.setvalue(e)} value={this.state.cliRange} />
										</Form.Group>
									</Col>
								}
								</Row>
								<Modal.Footer>
							          <Button onClick={() => this.allocateApiCli(false, '')} variant="secondary">CANCEL</Button>
							          <Button variant="primary" type="submit">Submit</Button>
						        </Modal.Footer>	
							</Form>

                         <Form method="post" onSubmit={this.removeAllocateCli}>
                            <Row>
								{(true) && 
								    <Col>
									<Form.Group className="form-group channels control-label">
											<Form.Label>Allocated Clis</Form.Label>
											<Form.Select className="dd" name="allocatedClisIds" onChange={(e) => { this.handleCliSelect(e.target.selectedOptions) }} multiple as="select">
												{allocatedCliDropdown}
											</Form.Select>
										</Form.Group>
									</Col> 
								}
							</Row>
							{ (this.state.allocatedCliListFlag) && <Modal.Footer>
							 <Button onClick={() => this.allocateApiCli(false, '')} variant="secondary">CANCEL</Button>
							 <Button variant="danger" type="submit">Remove</Button>
							</Modal.Footer>}
						 </Form>

						</Modal.Body>
					</Modal>

					<Modal size="md" show={this.state.confirmAllocateDedicatedCliUserId} onHide={() => this.allocateDedicatedCli(false, '')}>
						<Modal.Header closeButton>
							<Modal.Title id="example-modal-sizes-title-sm">
								Allocate Dedicated CLI
							</Modal.Title>
						</Modal.Header>
						<Modal.Body>
							<Form method="post" onSubmit={this.submitAllocateDedicatedCli}>
								<Row>
									<Col >
									<Form.Group className="form-group" >
											<Form.Check checked={this.state.manualDedicatedAllocation} onChange={( e ) => this.toggleChange( e, 'manualDedicatedAllocation' )} custom inline name="manualDedicatedAllocation" label="Manual Allocation" id="manualDedicatedAllocation" type="checkbox" />
									</Form.Group>
                                    </Col>
									<Col>
									<Form.Group className="form-group" >
											<Form.Check checked={this.state.bulkDedicatedAllocation} onChange={( e ) => this.toggleChange( e, 'bulkDedicatedAllocation' )} custom inline name="bulkDedicatedAllocation" label="Bulk Allocation" id="bulkDedicatedAllocation" type="checkbox" />
									</Form.Group>
									</Col>
								</Row>
								<Row>
								{(this.state.manualDedicatedAllocation || this.state.bulkDedicatedAllocation) &&
								<Row>
									<Col>
										<Form.Group className="form-group">
											<Form.Label>Select Locations</Form.Label>
											<Form.Select className="dd" name="locations" onChange={(e) => this.handleLocationSelect(e.target.selectedOptions)} multiple as="select" htmlSize="5" value={this.state.selectedLocations}>
												{this.state.locationList.length > 1 &&
													<option value="Select All">Select All</option>
												}
												{locationDropdownOptions}
											</Form.Select>
										</Form.Group>
									</Col>
								</Row>
								}
								{(this.state.manualDedicatedAllocation) && 
								    <Col>
									<Form.Group className="form-group channels control-label">
											<Form.Label>Select Multiple Cli</Form.Label>
											<Form.Select className="dd" name="allocateDedicatedCliId" onChange={(e) => { this.handleDedicatedSelect(e.target.selectedOptions) }} multiple as="select" htmlSize="8">
												{dedicatedCliDropdown}
											</Form.Select>
										</Form.Group>
									</Col> 
								}
								</Row>
								<Row>
								{(this.state.bulkDedicatedAllocation) && 
								    <Col sm={6}>
										<Form.Group className="form-group required control-label">
											<Form.Select className="dd" required name="dedicatedCliId" onChange={e => this.setvalue(e)} as="select">
												<option value="">Select Cli</option>
												{dedicatedCliDropdown}
											</Form.Select>
										</Form.Group>
									</Col>
								}
								</Row>
								<Row>
								{(this.state.bulkDedicatedAllocation) && 
								    
									<Col sm={6}>
										<Form.Group className="form-group required control-label">
											<Form.Control required name="dedicatedCliRange" type="number" placeholder="Range" onChange={e => this.setvalue(e)} value={this.state.dedicatedCliRange} />
										</Form.Group>
									</Col>
								}
								</Row>
								<Modal.Footer>
							          <Button onClick={() => this.allocateDedicatedCli(false, '')} variant="secondary">CANCEL</Button>
							          <Button variant="primary" type="submit">Submit</Button>
						        </Modal.Footer>	
							</Form>

                         <Form method="post" onSubmit={this.removeAllocateDedicatedCli}>
                            <Row>
								{(true) && 
								    <Col>
									<Form.Group className="form-group channels control-label">
											<Form.Label>Allocated Clis</Form.Label>
											<Form.Select className="dd" name="allocatedDedicatedClisIds" onChange={(e) => { this.handleDedicatedCliSelect(e.target.selectedOptions) }} multiple as="select">
												{allocatedDedicatedCliDropdown}
											</Form.Select>
										</Form.Group>
									</Col> 
								}
							</Row>
							{ (this.state.allocatedDedicatedCliListFlag) && <Modal.Footer>
							 <Button onClick={() => this.allocateDedicatedCli(false, '')} variant="secondary">CANCEL</Button>
							 <Button variant="danger" type="submit">Remove</Button>
							</Modal.Footer>}
						 </Form>

						</Modal.Body>
					</Modal>

				</Card.Body>
			</Card>

		);
	}
}

export default UserManagement;